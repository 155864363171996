@charset "utf-8";
/* CSS Document */
body {
  margin: 0px;
  padding: 0px;
  /* background-image: url('../../images/Prism_Teal_02.png');
    background-repeat: repeat;
    background-attachment: fixed;  */
  background: transparent linear-gradient(180deg, #d4f5ff 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  height: 650px;
}
.e-check {
  background-color: #2500a1 !important;
}

.pageBg {
  background-color: #c9f5ff;
}
.pageBgWhite {
  background-color: #fff;
}

.pageBgHeader {
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 54px;
  color: white;
}

.multiSelectFilter-input {
  border: 1px solid #ced4da !important;
  border-width: 1px 1px 1px 1px !important;
  border-radius: 5px !important;
  padding: 3px !important;
  background-color: #fff !important;
}
.multiSelectFilter-input-disabled {
  border: 1px solid #ced4da !important;
  border-width: 1px 1px 1px 1px !important;
  border-radius: 5px !important;
  padding: 3px !important;
  background-color: #e2e2e2 !important;
}
.e-close-hooker {
  background-color: #fff;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #2500a1 !important;
}

.row1 {
  margin-top: 0.5%;
  margin-right: -0%;
  margin-left: -0%;
  border: 1px solid;
}
.col-8 {
  border: 1px solid;
}
.col-4 {
  margin-top: 0.4%;
}
.col-10 {
  text-align: right;
}
.btn {
  margin: 0 3px;
  height: 36px;
}
.btnvalue {
  background-color: white;
  margin: 5px;
}
.btnvalue .btn {
  width: 15%;
}
.prim_But {
  padding: 15px;
}
.modalLoader .modal-content {
  background-color: transparent;
  border: transparent;
}
.modalCenter {
  margin-left: auto;
  margin-right: auto;
}
#list_nav {
  height: 64px;
  background: #393939;
  text-align: center;
  font-size: 30px;
  color: #fff;
}
#list_Instructions {
  border-radius: 8px;
  background: #f3f3f3;
  border: 1px solid #818181;
}
#account_details {
  font-size: 24px;
  color: #000;
}
#list_account {
  font-size: 20px;
  color: #000;
}
#table_details {
  font-size: 24px;
  color: #393939;
}
.container_assesment {
  padding-top: 18px;
}
#Editbtn {
  width: 200px;
  height: 44px;
  background-color: #ffffff;
  border-radius: 6px;
  font-size: 22px;
  padding: 0px;
  color: #0090a7;
  border: 1px solid #0090a7;
}
#Editbtn:hover {
  background-color: #eefdff;
  color: #0090a7;
  border: 1px solid #0090a7;
}
#refresh_list {
  width: 137px;
  height: 44px;
  background-color: #ffffff;
  margin-left: -8%;
  color: #0090a7;
  border: 1px solid #0090a7;
}
#refresh_list:hover {
  color: #0090a7;
  border: 1px solid #0090a7;
  background-color: #eefdff;
}
#next_list {
  width: 137px;
  height: 44px;
  background-color: #00c8e8;
  border: 1px solid #00c8e8;
  margin-right: -1.3%;
}
#next_list:hover {
  width: 137px;
  height: 44px;
  background-color: #18dfff;
  border: 1px solid #18dfff;
  margin-right: -1.3%;
}
#next_listdisabled {
  width: 137px;
  height: 44px;
  color: #000;
  background-color: #b9b9b9;
  margin-right: -1.3%;
}
.SummaryButtonDiv {
  display: flex;
  justify-content: flex-end;
}
#bottom_btn {
  margin-top: 20px;
}
#modalheader_list {
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  border: none;
  padding-top: 10px;
  height: 42px;
}
#modalheader_listErrorMessage {
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  font-size: 21px;
  color: #fff;
  text-align: center;
  border: none;
  padding-top: 9px;
}
#modaldis_list {
  font-size: 28px;
  color: #343434;
}
.modalHeader {
  margin: 0px !important;
  display: inline-flex;
}
.modalCompanyName {
  font-size: 18px;
  font-weight: 600;
}
.modalTitle {
  font-size: 17px;
  font-weight: 400;
  left: 0;
  right: 0;
  position: absolute;
}
.modalTitleDS {
  font-size: 17px;
  font-weight: 400;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
  top: 10px;
  color: white;
}
.btn-common {
  background-color: #2500a1;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #2500a1;
  height: 44px;
  width: 200px;
}
.btn-common:hover {
  background-color: #4114d9;
  border: 1px solid #4114d9;
}

#canclebtn_list {
  width: 200px;
  /* padding: 4px 5px 5px 5px; */
  background-color: #fff;
  border: 1px solid #2500a1;
  border-radius: 6px;
  font-size: 16px;
  color: #2500a1;
  font-weight: 500;
}
#canclebtn_list:hover {
  background-color: rgba(37, 0, 161, 0.1);
  border: 1px solid #2500a1;
}
#confirmbtn_list {
  width: 200px;
  /* padding: 4px 5px 5px 5px; */
  background-color: #2500a1;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #2500a1;
  font-weight: 500;
}
#confirmbtn_list:hover {
  background-color: #4114d9;
  border: 1px solid #4114d9;
}
.scrollTable {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: auto;
}
#list_table thead th {
  max-height: 42px;
  font-size: 15px;
  font-weight: 600;
  color: #393939;
  vertical-align: middle !important;
  background-clip: padding-box;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
}
#list_table tbody td {
  font-size: 14px;
}
.list_table_tbody {
  height: 100px;
  display: table-row;
}
.selection-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
/* #list_table tbody tr  {
    border-bottom: 1px solid #8E8E8E;
}

#list_table tbody tr th {
    border-bottom: 1px solid #8E8E8E;        
} */
#list_table row {
  height: 30px;
  font-size: 16px;
  color: #393939;
  background-color: white;
}
#list_table thead th:nth-child(11) {
  font-size: 14px;
}
#list_table thead th:nth-child(12) {
  font-size: 14px;
}
#btn_grp {
  float: right;
}
.modal-header .close:hover {
  color: white;
  opacity: 1;
}
.modal-header .close {
  z-index: 99999;
}
h1,
.h1 {
  font-size: 42px;
}
h5,
.h5 {
  font-size: 22px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}
.h4,
h4 {
  font-size: 2rem;
  color: #225765;
}
.objectiveTagLine {
  font-size: 1.3rem;
  font-style: italic;
  color: #797979;
}
a {
  color: #0066ff;
  font-size: 18px;
}
.vertical-text {
  transform: rotate(270deg);
  transform-origin: left bottom 25;
  background: #e23737;
  color: #fff;
  border: 1px solid #ccc;
  text-transform: uppercase;
  border: 1px solid #b52c2c;
  text-transform: 1px 1px 0px rgba(0, 0, 0, 0.5);
}
.table {
  width: 100%;
  margin-bottom: 0px;
}
.table th .table tr {
  padding: 3px;
}
.serraHeader {
  /*	padding: 16px 28px;*/
  background-color: #ffffff !important;
}
.brandName {
  font-size: 30px;
  color: #0090a7;
  font-weight: 600;
}
.toggleButton {
  margin-bottom: 1rem;
  height: auto;
  color: red;
  background-color: white;
  border-color: red;
  width: 100%;
}
#divmiddle {
  height: 44px;
}
.toggleCollapse {
  margin-bottom: 1rem;
}
.textfieldCentered {
  text-align: center;
  vertical-align: middle !important;
}
.textfield {
  text-align: left;
  padding-left: 5px;
  vertical-align: middle !important;
}
.textfieldSizing {
  text-align: center;
  vertical-align: middle !important;
}
.sizingeHelathColoredTDValidation {
  width: 60px;
  height: 30px;
  padding-top: 2px;
}
.sizingeHelathColoredTD {
  width: 104px;
  height: 30px;
  padding-top: 2px;
}
.sizingHealthColoredTDCenteredDiv {
  justify-content: center;
  display: flex;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.452);
  color: white;
}
.numberfield {
  text-align: right;
  padding-right: 5px;
  vertical-align: middle !important;
}

.tableSelectionCol {
  vertical-align: middle !important;
  text-align: center;
}
.form-check-input {
  position: inherit !important;
}
.form-check-input-nontable {
  position: absolute !important;
}
/* .tableview th, .tableview td {
        padding: 0px !important;
        vertical-align: inherit;
} */
.collapsediv {
  display: inline-flex;
  margin-bottom: 1rem;
  min-height: 105px;
  color: #393939;
  background-color: white;
  border: 1px solid #a4a4a4;
  text-align: left;
  padding-left: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  width: 98%;
}
.resize-collapsediv {
  height: auto;
}
.imageclass {
  float: right;
  padding-right: 5%;
  padding-bottom: 1%;
  padding-top: 1px;
  cursor: pointer;
}
.nav-tabs {
  cursor: pointer;
  border-bottom: none !important;
  margin-bottom: 20px;
  justify-content: space-around;
}
.imageclassCenter {
  padding-right: 5%;
  padding-bottom: 1%;
  padding-top: 1px;
  cursor: pointer;
}
.divchange {
  width: 100%;
  margin-bottom: 15px;
}
.row {
  width: 100%;
  margin: 0px !important;
}
.card {
  border: 1px solid #bbbbbb;
  border-radius: 8px;
}
.card-body {
  padding: 10px 8px !important;
  border: 1px solid #bbbbbb;
  border-radius: 8px;
}
.card-header {
  padding: 0px !important;
  background-color: transparent;
  border-bottom: 3px solid #6fb1cb;
}
.container-verf {
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 95%;
}
.container-verf-padding {
  padding: 15px;
}
.container-verf-margin {
  margin: 15px;
}
.container-verf-select-plan-padding {
  padding-top: 66px;
}
.container-verf-static-page {
  padding-top: 0px;
}
.btn-detils {
  height: 36px;
  width: 200px;
  border-radius: 6px;
  background: #ffffff;
}
.btn-close {
  height: 44px;
  width: 137px;
  border-radius: 6px;
  background: #ffffff;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #18dfff !important;
  border-color: #18dfff !important;
}
.btn-primary {
  color: #fff;
  background-color: #00c8e8 !important;
  border-color: #00c8e8 !important;
}
.btn-outline-primary:hover {
  background-color: #e9fcff !important;
  border-color: #d8d8d8 !important;
  color: #2500a1 !important;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}
.btn-outline-primary {
  border-color: #d8d8d8 !important;
  color: #2500a1 !important;
}
.border-dark {
  border-color: #343a40 !important;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
}
.outerspan {
  display: inline-block;
  width: 72px;
}
.outerspan-summary {
  display: inline-block;
  margin-right: 2px;
}
.overviewTextCompParent {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
}
.overviewTextCompParentExpanded {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
}
.li-result-nobullet {
  list-style-type: none;
  padding-left: 0px;
  text-align: left;
}
.li-result-nobullet-bold {
  list-style-type: none;
  padding-left: 0px;
  text-align: left;
  font-weight: 500;
}
.ul-bullet-inside {
  list-style-position: inside;
}
.div-center-ul {
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.div1 {
  height: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-left: 1px solid #80808045;
  border-right: 1px solid #80808045;
  border-top: 1px solid #80808045;
}
.div2 {
  height: 44px;
  border-left: 1px solid #80808045;
  border-right: 1px solid #80808045;
}
.div3 {
  height: 16px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid #80808045;
  border-right: 1px solid #80808045;
  border-bottom: 1px solid #80808045;
}
img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.maindiv {
  display: inline-flex;
  margin: auto 10px auto auto;
}
.collapseTransition {
  transition: all 0.7s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  min-height: 0px;
  margin-bottom: 1rem;
}
.maindivCollapse {
  /* float: right; */
  margin-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.maindiv-summary {
  float: right;
  margin-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: flex;
  position: relative;
  padding-bottom: 2%;
}
.maindiv-text-summary {
  float: right;
  width: 100%;
  margin-top: 10px;
  min-height: 120px;
}
.maindiv-text-overview {
  flex-grow: 1;
  min-width: 350px;
  margin-right: 10px;
  width: 50%;
}
.maindiv-text-summary-grad {
  background: transparent
    linear-gradient(
      180deg,
      rgba(209, 234, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat padding-box;
  height: 100%;
}
.maindiv-text-overview-grad {
  background: transparent
    linear-gradient(
      180deg,
      rgba(209, 234, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat padding-box;
  height: 100%;
}
.overview-risize-flex-collapseDiv {
  display: flex;
}
.summary-card-col {
  padding-right: 0px;
  padding-left: 10px;
}
.CpuClass::before {
  content: url("../../images/CPU_TestIcon_01.png");
  padding-left: 10px;
  float: left;
}
.DbCpuClass::before {
  content: url("../../images/DB_vCore_02.png");
  padding-left: 10px;
  float: left;
}
.MemoryClass::before {
  content: url("../../images/Memory_TestIcon_01.png");
  padding-left: 10px;
  float: left;
}
.DbMemoryClass::before {
  content: url("../../images/DB_DataDisk_02.png");
  padding-left: 10px;
  float: left;
}
.NetworkClass::before {
  content: url("../../images/Network_TestIcon_01.png");
  padding-left: 10px;
  float: left;
}
.DbNetworkClass::before {
  content: url("../../images/DB_LogDisk_02.png");
  padding-left: 10px;
  float: left;
}
.DbOsClass::before {
  content: url("../../images/DB_vCore_Memory_02.png");
  padding-left: 15px;
  float: left;
}
.OsClass::before {
  content: url("../../images/Disk_TestIcon_01.png");
  padding-left: 15px;
  float: left;
}
.tableLeftHead {
  text-align: left;
  vertical-align: middle;
  margin-left: 42px;
}

.tableLeftHeadSummary {
  text-align: left;
  vertical-align: middle;
  margin-left: 5px;
}
#collapse_head {
  /*	line-height: 78.44px;*/
  /*	width: 240px;*/
  display: flex;
  align-items: center;
}
#overViewCollapseTitle {
  font-size: 22px;
  font-weight: 500;
}
#rowOverview {
  padding-top: 26px;
  border-bottom: 1px solid #a2a2a2;
  font-size: 36px;
  color: #5a5a5a;
  width: 100%;
  text-align: center;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #393939 !important;
  border: 0px;
}
#optTbl.nav-tabs .nav-item {
  margin-bottom: 5px;
  position: relative;
  top: 0px;
  width: 33%;
  font-weight: 600;
}

.nav-tabs .nav-item {
  margin-bottom: 5px;
  position: relative;
  top: 11px;
  width: 33%;
}

#optTbl.nav-tabs .nav-item .nav-link:hover {
  color: #393939;
  /*    border-bottom:6px solid #57C9E4 !important;
        background-image: linear-gradient(to top, #ECFCFF, #FFFFFF);*/
}
nav-tabs .nav-item .nav-link:hover {
  color: #225765;
  border-bottom: 6px solid #57c9e4 !important;
  background-image: linear-gradient(to top, #ecfcff, #ffffff);
}

#optTbl.nav-tabs .nav-link.active {
  color: #393939 !important;
  border-bottom: 0px !important;
  background-color: #fff;
  height: 44px !important;
  padding: 10px 5px !important;
}

#dashtabs.navClass {
  margin: auto;
  border-bottom: 0px !important;
  border-top: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  border-radius: 5px 5px 0px 0px;
}

#dashtabs.nav-tabs .nav-item {
  margin-bottom: 0px !important;
  position: relative;
  top: 0px;
  /*    width:33.33%;*/
  text-align: center;
}
#dashtabs.nav-tabs .nav-item .nav-link:hover {
  color: #393939;
}

#dashtabs.nav-tabs .nav-link.active {
  color: #393939 !important;
  border-bottom: 0px !important;
  background-color: #fff;
  height: 60px !important;
  padding: 10px 5px !important;
  /*    border-left: 1px solid #c9c9c9;
    border-right: 1px solid #c9c9c9;*/
}

#dashtabs.nav-tabs .nav-link {
  border: 0px;
  color: #9b9b9b;
  background-color: #e3e3e3;
  height: 60px !important;
  padding: 10px 5px !important;
  border-bottom: 1px solid #c9c9c9;
  font-weight: 600;
  font-size: 22px;
}

#dashtabcontent {
  margin-bottom: 15px;
  border-bottom: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  border-radius: 0px 0px 5px 5px;
  height: 95%;
}

.nav-tabs .nav-link.active {
  color: #000;
  border-bottom: 6px solid #2500a1 !important;
}
#optTbl.nav-tabs .nav-link {
  border: 0px;
  color: #ffffff;
  background-color: #b9b9b9;
  height: 44px !important;
  padding: 10px 5px !important;
}
.nav-tabs .nav-link {
  border: 0px;
  color: #888888;
}
h3,
.h3 {
  font-size: 34px;
}
#rowOverviewDetail {
  color: #393939;
  font-size: 16px;
  width: 100%;
  text-align: center;
  line-height: 25px;
}
p {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
a:not([href]):not([tabindex]) {
  color: #888888;
  text-decoration: none;
}
.card-body-container {
  margin: 25px 10px 10px 10px;
}
.card-header-title {
  margin-bottom: 0px;
}
.divSlice {
  height: 44px;
  width: 14px;
}
.divSliceLastDisk {
  height: 44px;
  width: 16px;
  border-right: 1px solid #80808045;
}
.divDisk {
  height: 44px;
  width: 32px;
}
.diskStyle {
  display: flex;
}
.divSliceCost {
  width: 14px;
  height: 16px;
  border-right: 1px solid white;
}
.divDiskCost1 {
  height: 16px;
  border: 1px solid #80808045;
  border-top-left-radius: 6px;
  border-right: 1px solid white;
}
.divDiskCost {
  height: 16px;
  border-right: 1px solid white;
}
.divresizeDiskCost {
  height: 16px;
}
.divresizeSliceCost {
  width: 14px;
  height: 16px;
  border: 1px solid #80808045;
}
.divDiskHealth {
  height: 16px;
  border: 1px solid #80808045;
  border-bottom-left-radius: 6px;
  border-right: 1px solid white;
}
.divDiskSliceCost {
  width: 16px;
  height: 16px;
  border-top-right-radius: 6px;
  border: 1px solid #80808045;
}
.divDiskSliceHealth {
  width: 16px;
  height: 16px;
  border-bottom-right-radius: 6px;
  border: 1px solid #80808045;
}
.outerspanDisk {
  display: inline-block;
}
.outerNext {
  display: inline-block;
  margin-bottom: 26px;
  padding: 0px 10px;
}
.outerNextVal {
  display: inline-block;
  padding: 0px 10px;
  margin-top: auto;
}
.Outer {
  margin-right: 5px;
}
.arrow {
  float: left;
  padding-right: 20px;
  padding-top: 0px;
}
#btn-details {
  margin-top: 56%;
  height: 44px;
  width: 200px;
  background-color: #00b9ff;
  border: 1px solid #0a79fa;
  border-radius: 6px;
  color: #fff;
}
#btn-details:hover {
  background-color: #0a79fa;
  color: white;
}
#tableSizing thead th {
  height: 48px;
  font-size: 15px;
  color: #393939;
  font-weight: 600;
}
#tableSizing tbody td {
  font-size: 14px;
}
.tableSizingBorder th,
.tableSizingBorder td {
  border: 1px solid #8e8e8e !important;
}
#tableSizing th,
#tableSizing td {
  padding: 0px;
  vertical-align: inherit;
  padding-left: 5px;
  padding-right: 5px;
  width: 86px;
}
thead {
  text-align: center;
}
#tableSizing thead th:nth-child(1) {
  background-color: white;
  height: 34px;
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 0px solid white;
}
#tableSizing .table thead th:nth-child(1) {
  background-color: #d8d8d8;
  height: 34px;
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 0px solid white;
}
#tableSizing tbody tr {
  height: 56px;
}
#tableSizing tbody tr:nth-child(4) {
  height: 37px;
}
#tableSizing tbody tr:nth-child(5) {
  height: 37px;
}
#tableSizing tbody tr:nth-child(6) {
  height: 37px;
}
.table .table tr th {
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.table tr td {
  vertical-align: middle;
}
.table .table tr td {
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: none;
}
.resize-ben-title-text {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  color: #0063ae;
  text-align: left;
  list-style: none;
}
.infoicon {
  font-size: 30px;
  color: #006be5;
  height: 44px;
}
.info-icon {
  color: #006be5;
}
#infoiconDisabled {
  font-size: 30px;
  color: #8e8e8e;
  height: 44px;
}
.sizingtzble_head {
  width: 260px;
  height: 34px;
  background-color: #d8d8d8;
  font-size: 22px;
  color: #000;
}
.box-title-card {
  width: 100%;
  text-align: center;
  font-size: 36px;
  color: #00395e;
  padding-bottom: 15px;
}
.infoText p {
  font-size: 20px;
}
.descDivCard div {
  margin-bottom: 5px;
}
.descDivCard {
  font-size: 20px;
  color: #535353;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
}
#pwLoader {
  background-color: #2500a1 !important;
}
#planNav {
  background-color: unset;
}
.currentPlan {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  color: #393939;
  line-height: 1;
  align-self: center;
  align-content: center;
}
.plan-card {
  height: 360px;
  width: 300px;
}
.plan-card-header {
  height: 60px;
  border: none;
  background-color: #002f5d !important;
  color: #ffffff;
  border-radius: 8px;
}
.plan-card-header-disabled {
  height: 60px;
  border: none;
  background-color: #c4c4c4 !important;
  color: #ffffff;
}
.planCardHeaderTitle {
  color: #ffffff;
  font-size: 34px;
  text-align: center;
  height: 100%;
  line-height: 60px;
}
.descDivCard p {
  font-size: 20px;
  padding: 0px;
  margin: 0px;
  color: #343434;
}
.planCardHeaderTitleDisabled {
  color: #ffffff;
  font-size: 34px;
  text-align: center;
  height: 100%;
  line-height: 60px;
}
.subDescDivCard {
  font-size: 20px;
  color: #343434;
  text-align: center;
  font-weight: 600;
  border-radius: 10px;
}
.subDescSpanCard {
  font-size: 30px;
  color: #00395e;
  text-align: center;
  font-weight: 400;
}
.plan-card {
  height: 320px;
  width: 300px;
  border: 1px solid #78b6c1;
}
.plan-card-header {
  height: 60px;
  border: none;
  background-color: #393939;
  color: #ffffff;
  border-radius: 8px;
}
.plan-card-body-row-comming-soon {
  padding-top: 0px;
  color: #0080ff;
  font-size: 34px;
}
.card-header:first-child {
  border-radius: 8px 8px 0px 0px;
}
.modal-header {
  background-color: #0076c2;
  color: #ffffff;
}
.modal-body div p {
  font-size: 20px;
  color: #343434;
  line-height: 33px;
}
.account_container {
  max-width: 460px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.account-container-plan {
  height: 120px;
  background-color: #393939;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
#btnaccount_save {
  background-color: #2500a1;
  border: 1px solid #2500a1;
  border-radius: 3px;
  color: #fff;
  width: 20%;
  font-size: 15px;
}
#btnaccount_save:hover {
  background-color: #4114d9;
  width: 20%;
  border: 1px solid #4114d9;
}
#btn_disabled {
  background-color: rgb(189, 202, 204);
  border-radius: 3px;
  border: 1px solid rgb(175, 181, 182);
  color: #000;
  width: 20%;
  font-size: 15px;
}
#btncancel_disabled {
  background-color: rgb(189, 202, 204);
  border-radius: 3px;
  border: 1px solid rgb(175, 181, 182);
  color: #000;
  font-size: 15px;
  width: 20%;
}
#btnaccount_cancel {
  background-color: #fff;
  color: #2500a1;
  border-radius: 3px;
  font-size: 15px;
  border: 1px solid #2500a1;
}
#btnaccount_cancel:hover {
  background-color: rgba(37, 0, 161, 0.1);
  border: 1px solid #2500a1;
}
#btnaccount_test {
  background-color: #fff;
  color: #2500a1;
  border-radius: 3px;
  width: 20%;
  font-size: 15px;
  border: 1px solid #2500a1;
}
#btnaccount_test:hover {
  background-color: rgba(37, 0, 161, 0.1);
  border: 1px solid #2500a1;
  border-radius: 3px;
}
.plan-card-body {
  border-radius: 0px 0px 8px 8px;
  border: none;
}

.plan-card-body-row-override-pad {
  padding-top: 0px;
  text-decoration: underline;
  color: #747474;
}
.plan-card-body-row a {
  font-size: 24px;
  color: #747474;
}
.plan-card-body-row-comming-soon {
  padding-top: 0px;
  color: #c15b78;
  font-size: 24px;
  font-weight: 600;
}
.card-header:first-child {
  border-radius: 8px 8px 0px 0px;
}
.modal-header {
  background-color: #0076c2;
  color: #ffffff;
}
#knowMore {
  padding: 15px 20px;
}
#knowMore p {
  font-size: 18px;
  color: #343434;
}
#ResultEnableBtn:hover {
  background-color: #18dfff;
}
.whiteBtn:hover {
  background-color: #eefdff;
  color: #007bff;
}
#primaryBtn:active {
  background-color: #18dfff;
  border: 1px solid #18dfff;
}
#whiteBtn:active {
  background-color: #eefdff;
  color: #007bff;
}
.plan-card-body {
  border-radius: 0px 0px 8px 8px;
  border: none;
}
.plan-card-body-row {
  padding-top: 18px;
  height: 94px;
  width: 100%;
  margin: auto;
  font-size: 20px;
  color: #393939;
}
.plan-card-body-row-override-pad {
  padding-top: 0px;
  text-decoration: underline;
  color: #747474;
}
.plan-card-body-row a {
  font-size: 24px;
  color: #747474;
}
.plan-card-body-row-comming-soon {
  padding-top: 0px;
  color: #c15b78;
  font-size: 24px;
  font-weight: 600;
}
.card-header:first-child {
  border-radius: 8px 8px 0px 0px;
}
.modal-header {
  background-color: #0076c2;
  color: #ffffff;
}
#knowMore {
  padding: 15px 20px;
}
#knowMore p {
  font-size: 18px;
  color: #343434;
}
#knowMoreFooter {
  border: none;
  padding: 0px 15px 15px 15px;
}
#btn_addAzureaccnt {
  background-color: #0090a7;
  color: #fff;
  border-radius: 6px;
  width: 266px;
  border: 1px solid #0090a7;
  font-size: 17px;
  padding: 5px 18px;
}
#btn_addAzureaccnt:hover {
  border-radius: 6px;
  width: 266px;
  font-size: 17px;
  padding: 5px 18px;
  color: #fff;
  background-color: #18dfff;
  border-color: #00b9ff;
}
#btn_uploadfile {
  background-color: rgb(189, 202, 204);
  border-radius: 6px;
  border-color: 1px rgb(175, 181, 182);
  color: #000;
  width: 266px;
  font-size: 17px;
  padding: 5px 18px;
}
.legendclass {
  float: left;
  width: 100%;
  height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
  padding-top: 1px;
  align-items: stretch;
}
.legendLable {
  display: flex;
}
.Good {
  background: #228b22;
  color: white;
  border-radius: 15px 0px 0px 15px;
}
.TooHigh {
  background: #880c25;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #fff;
}
.High {
  background: #ec3257;
  color: #fff;
}
.Low {
  background: #6a6a6a;
  color: white;
}
.TooLow {
  background: #0c171c;
  color: white;
}
.Ok {
  background: #448744;
  color: #fff;
}
.Fair {
  background: #deae25;
  color: #000;
}
.Poor {
  background: #c9393d;
  color: white;
  border-radius: 0px 15px 15px 0px;
}
#tabs {
  max-width: 1260px;
}
#costcolor {
  display: flex;
  padding: 0px;
  line-height: 1.5;
  text-align: center;
}
#healthcolor {
  margin-right: 30%;
  margin-left: 30%;
  line-height: 1.5;
  text-align: center;
}
#infoicon {
  font-size: 30px;
  color: #006be5;
}
#infoicon .tooltiptext {
  visibility: hidden;
  width: 437px;
  height: auto;
  background-color: #ffffff;
  color: #393939;
  box-shadow: 0px 0px 10px #888888;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 18px;
  /* background-image: url(../../images/test.png); */
  position: absolute;
  z-index: 1;
  top: -110px;
  left: -62px;
  display: none;
}
#infoicon:hover .tooltiptext {
  visibility: visible;
  display: block;
}
.distanceInfo {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 60px;
}
.distanceInfo1 {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
}
.staticContentParentDiv {
  text-align: left;
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  border-bottom: 1px solid #c4c4c4;
}
.staticContentParentDiv:last-child {
  border: none;
}
.staticHeaderParentDiv {
  text-align: center;
}
.close {
  float: right;
  font-size: 40px;
  font-weight: 100;
  line-height: 0.5;
  color: #fff;
  /* text-shadow: 0 1px 0 #fff; */
  opacity: 1;
}
.divText {
  padding-right: 15px;
  display: "inline-flex";
  font-weight: 600;
}
.InfoDiv {
  display: flex;
  justify-content: center;
  color: #393939;
  font-size: 16px;
  width: 100%;
  text-align: center;
  line-height: 25px;
}
#smallModalBody {
  display: table;
  min-height: 200px;
}
#smallModalBody #contentDiv {
  display: table-cell;
  vertical-align: middle;
}
#list_nav1 {
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  text-align: center;
  font-size: 30px;
  color: #fff;
  margin-top: 36px;
}
#list_plan_nav1 {
  height: 94px;
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  text-align: center;
  font-size: 30px;
  color: #fff;
}
/* .div1 .tooltip{
        background-color: red;
 text-shadow: 1px 1px rgba(0, 0, 0, 0.452); 
}	 */
.tooltip {
  height: 200px;
}
#divCoststatic {
  height: 16px;
  border-bottom: 1px dashed #393939;
  font-size: 11px;
  width: 50px;
  text-align: left;
}
#divHealthstatic {
  height: 14px;
  border-top: 1px dashed #393939;
  font-size: 11px;
  width: 50px;
  text-align: left;
}
.resize-ben-title-text :before {
  content: "";
  border-color: transparent #0063ae;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1.3em;
  top: 1em;
  position: relative;
}
.w-100 {
  width: 100% !important;
  font-size: 30px;
  padding-left: 34px;
  line-height: 0.9;
}
.outerline {
  height: 75px;
  width: 1px;
  background: #39393926;
  display: inline-block;
  margin: 0px 10px;
}
.DeleteForm {
  display: flex;
  line-height: 2;
  margin-bottom: 54px;
  margin-top: 14px;
  margin-left: 80px;
  font-size: 19px;
}
.rowContainer {
  margin: 7%;
  margin-top: 1%;
  text-align: center;
}
.cardContainer {
  margin-top: 15px;
  padding: 25px 20px 35px;
  text-align: center;
  /*	background-color: #DCFAFF;*/
}
.prim_But1 {
  display: flex;
  justify-content: flex-start;
}
.prim_But {
  display: flex;
  justify-content: flex-end;
}
.wrapper {
  clear: both;
  float: left;
  width: 100%;
  height: auto;
}
.outer-container {
  padding: 20px;
  margin: auto auto;
  width: 40%;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 15px #41a1be59;
}
@media (min-width: 300px) and (max-width: 767px) {
  .outer-container {
    width: 100%;
  }
}
.outer-container-success {
  padding: 15px 0px 15px 0px;
  margin: 20px auto;
  max-width: 1230px;
  width: 60%;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 15px #41a1be59;
}
.box-titel {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
}
.brand-container {
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}
.brand-container-plan {
  height: 88px;
  background-color: #393939;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.info-container {
  width: 100%;
  padding: 5px 10px;
  text-align: center;
}
.sign-up-link {
  /* font-weight: 700; */
  text-decoration: underline;
}
.sign-up-link a {
  font-size: 16px;
}
.bg-container1 {
  width: 100%;
  height: 50vh;
  /* background: #393939; */
  color: white;
  position: fixed;
}
#accountSuccess {
  height: 35vh;
}
#accountSuccess1 {
  height: 30vh;
}
.bg-container2 {
  width: 100%;
  height: 50vh;
  background-color: #afe5fc;
}
.frm-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  top: 20px;
}
.bg-dark-serra {
  background-color: #ffffff !important;
  height: 66px;
  color: #393939;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
}

.navbar-nav .nav-link {
  color: #393939;
}
.navbar {
  padding: 0px;
}
.NavHeader {
  margin-top: 50px;
  background-color: #bbbbbb;
  height: 46px;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: -40px;
}

#SignInClass {
  display: block;
  margin: 0 auto;
  width: 100%;
  top: 20px;
  position: absolute;
}
#SignUpClass {
  display: block;
  margin: 0 auto;
  width: 100%;
  top: 20px;
  position: absolute;
}
.container-verf-question {
  display: flex;
  width: 100%;
  margin: 0px 0px 0px 0px;
}
.verf-qestion {
  width: 67%;
  background-color: #afafaf;
  margin: 3px 10px 4px 0px;
  padding: 10px 0px 0px 14px;
  color: #393939;
}
.reset-btn {
  width: 30%;
}
.outer-container1 {
  padding: 20px;
  margin: 0 auto;
  width: 90%;
  border-radius: 10px;
  background-color: #f4f3f3;
  margin-bottom: 20px;
}
.input-group {
  width: 100%;
}
.checkbox {
  margin-left: 22px;
}
.abc {
  float: right;
}
.abc a {
  font-size: 16px;
}
.FormSelect {
  width: 100%;
}
#passwordConfirm {
  width: 91%;
}
.Heading {
  margin-top: 66px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
.HeadingSuccess {
  margin-top: 66px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #610058;
}
.HeadingTitle {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  color: #fff;
}
@media (min-width: 300px) and (max-width: 767px) {
  .Heading {
    font-size: 20px;
  }
  .HeadingTitle {
    font-size: 20px;
  }
  #overViewCollapseTitle {
    font-size: 14px;
    font-weight: 400;
  }
  .collapsediv {
    height: 60px;
  }
}
.tooltipSignup .tooltip {
  left: 0px !important;
}

.tooltipSignup .tooltip-inner {
  background-color: white;
  color: #393939;
  margin-left: 72px;
  margin-top: 24px;
  border: 1px solid gray;
  box-shadow: 0px 0px 10px #888888;
  border-radius: 8px;
  max-width: 400px;
}
.tooltipSignup .tooltip.show {
  opacity: 1;
}
#btnSinginSignup {
  background-color: #2500a1;
  border: 1px solid #2500a1;
  border-radius: 3px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
}
#btnSinginSignup:hover {
  background-color: #4114d9;
  border: 1px solid #4114d9;
}
#btnSinginSignup_disabled {
  background-color: #9ca5ad;
  border-radius: 3px;
  border: 1px solid #9ca5ad;
  color: #000;
  margin-top: 20px;
  margin-bottom: 10px;
}
#TableHeader {
  height: 36px;
  background-color: #6fb1cb;
  font-size: 20px;
  color: #fff;
}
#ColumnHeader {
  height: 52px;
  /* background-color: #D8D8D8; */
  font-size: 15px;
}
#ColumnHeader th {
  color: #393939 !important;
  font-weight: 600 !important;
}
#InnerRow {
  height: 20px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
  padding-left: 2px;
}
#leftpadding {
  padding-left: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}
#rightpadding {
  padding-right: 2px;
}
#HalfRowBorder {
  height: 30px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px solid #8e8e8e;
}
#HalfRow {
  height: 30px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
}
#HalfRowNtwBorder {
  height: 60px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px solid #8e8e8e;
}
#HalfRowNtw {
  height: 60px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
}
#DetailsPanel {
  margin: auto;
}
#HalfRowBorderLimit {
  height: 60px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px solid #8e8e8e;
}
#HalfRowLimit {
  height: 60px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
}
#ViewResultBorder {
  border: 1px solid #b5b5b5;
  font-size: 24px;
  color: #000;
  height: 350px;
  margin: 5%;
  margin-top: 2%;
  border-radius: 8px;
}
#ResultEnableBtn {
  background-color: #00c8e8;
  font-size: 20px;
  color: #fff;
  border: none;
  border-radius: 6px;
}
#ResultDisableBtn {
  background-color: #f2f2f2;
  font-size: 20px;
  color: #b5b5b5;
  height: 50px;
  width: 430px;
}
#ResultBtnDivMargin {
  margin: 5%;
}
#NumRightAlign {
  height: 40px;
  font-size: 14px;
  color: #000;
  padding: 0px;
  vertical-align: middle;
  text-align: right;
  padding-left: 2px;
  padding-right: 2px;
}
#DoubleInnerRow {
  height: 40px;
  font-size: 14px;
  color: #393939;
  padding: 0px;
  vertical-align: middle;
  text-align: left;
  padding-left: 2px;
}
#NumRightNtwAlign {
  height: 120px;
  font-size: 14px;
  color: #393939;
  padding: 0px;
  vertical-align: middle;
  text-align: right;
  padding-left: 2px;
}
#veticalAlignment {
  vertical-align: middle;
}
#ArrowImage {
  margin: auto;
  display: block;
  /*max-width: 150%;*/
}
#ShowDetails {
  display: flex;
  justify-content: flex-end;
}
.description-cont {
  display: flex;
  background-color: #000;
  padding-top: 66px;
  box-sizing: border-box;
}
.sum-header {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  position: sticky;
  top: 60px;
  color: #393939;
  align-items: center;
  font-size: 18px;
  border: 1px solid #c9c9c9;
  border-bottom: none;
  z-index: 1000;
  font-weight: 600;
  margin-top: 20px;
}
.sum-cont {
  display: flex;
  background-color: #ffffff;
  justify-content: space-around;
  /*height: 1000px;*/
}
.Sub-Heading {
  display: flex;
  justify-content: center;
  /*background-color: #FFFFFF;*/
  /*border: 1px solid #7F7F7F;*/
  height: 30px;
  color: #393939;
  padding: 10px 0px 0px 0px;
  align-items: center;
  font-size: 24px;
  padding: 2vw;
  font-weight: 600;
}
.mod-tab {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  /* background-color: #FFFFFF; */
  flex-basis: 33%;
  height: 100px;
  padding: 10px;
  text-align: center;
}
/*.Sub-Heading::after {
          display: flex;
          content: " ";
          width: 100%;
          height: 50px;
          background-color: aqua;
  }
*/
#modTwo {
  border-right: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
}
.sum-item {
  display: flex;
  z-index: 130;
  flex-basis: 33%;
  padding-bottom: 10px;
}
.sum-inner-item {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 0px 10px;
  font-size: 1rem;
}
.Sub-legend {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  border-bottom: 1px solid #c8c8c8;
  height: 66px;
  position: sticky;
  top: 160px;
  color: #393939;
  align-items: center;
  font-size: 16px;
  z-index: 1000;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
}
@media (min-width: 300px) and (max-width: 767px) {
  .Heading {
    font-size: 20px;
  }
  .HeadingTitle {
    font-size: 20px;
  }
  .HeadingSignIn {
    font-size: 20px;
  }
  .HeadingTitleSignIn {
    font-size: 20px;
  }
  #overViewCollapseTitle {
    font-size: 14px;
    font-weight: 400;
  }
  .collapsediv {
    height: 60px;
  }
  .div1 {
    height: 8px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .div2 {
    height: 22px;
  }
  .div3 {
    height: 8px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .outerspan {
    width: 35px;
  }
  #divCoststatic {
    height: 7px;
    width: 20px;
    font-size: 6px;
  }
  #divHealthstatic {
    height: 7px;
    font-size: 6px;
    width: 20px;
  }
  .divSlice {
    height: 22px;
    width: 7px;
  }
  .divSliceLastDisk {
    height: 22px;
    width: 8px;
  }
  .divDisk {
    height: 22px;
    width: 16px;
  }
  .divSliceCost {
    width: 7px;
    height: 8px;
  }
  .divDiskCost1 {
    height: 8px;
    border-top-left-radius: 3px;
  }
  .divDiskCost {
    height: 8px;
  }
  .divresizeDiskCost {
    height: 8px;
  }
  .divresizeSliceCost {
    width: 7px;
    height: 8px;
  }
  .divDiskHealth {
    height: 8px;
    border-bottom-left-radius: 3px;
  }
  .divDiskSliceCost {
    width: 8px;
    height: 8px;
    border-top-right-radius: 3px;
  }
  .divDiskSliceHealth {
    width: 8px;
    height: 8px;
    border-bottom-right-radius: 3px;
  }
  #ResultEnableBtn {
    background-color: #00c8e8;
    font-size: 10px;
    color: #fff;
  }
  #ViewResultBorder {
    border: 1px solid #b5b5b5;
    font-size: 16px;
    color: #000;
    height: 200px;
    margin: 5%;
    border-radius: 8px;
  }
  .currentPlan {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    color: #393939;
    line-height: 1;
    align-self: center;
    align-content: center;
  }
  #list_nav1 {
    height: 43px;
    font-size: 16px;
  }
  .sum-header {
    font-size: 9px;
    height: 60px;
  }
  h4,
  .h4 {
    font-size: 0.5rem;
  }
  #divmiddle {
    height: 22px;
  }
  .divText {
    padding-right: 15px;
    font-size: 8px;
  }
  .InfoDiv {
    display: inline;
  }
  .Sub-legend {
    height: 40px;
    top: 125px;
    font-size: 8px;
  }
  .legendclass {
    float: left;
    width: 100%;
    height: 24px;
    font-size: 8px;
    margin-bottom: 10px;
    padding-top: 1px;
    align-items: stretch;
  }
  #costcolor {
    line-height: 2;
  }
  #healthcolor {
    line-height: 2;
  }
  .mod-tab {
    height: 60px;
  }
}
.ManageAcntTable {
  margin-left: auto;
  margin-right: auto;
}
.multiSelectList {
  display: inline-flex;
  line-height: 1;
  width: 50%;
}
.multiSelectFilter {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-end;
}
.css-1alnv5e:nth-child(1) {
  display: none !important;
}
.reportChart {
  background-color: white;
  text-align: center;
}
#roportRightChart {
  border: 1px solid #8080805c;
  padding: 30px;
  padding-left: 65px;
}
#reportTable {
  width: 98%;
  margin: auto;
}
#PPUbtn {
  border: 1px solid #1b9bbdb5;
  background: white;
  color: #20bbd8fc;
  line-height: 1;
}
.table th,
.table td {
  padding: 0.25rem !important;
}
#SelectAllCheck {
  position: relative;
  margin: 5px;
}

/* .my-react-select .css-1rhbuit-multiValue:nth-child(7) {
        font-style: italic;
  }
  .my-react-select .css-1rhbuit-multiValue:nth-child(2) .css-1alnv5e,
  .my-react-select .css-1rhbuit-multiValue:nth-child(n+5) {
        display: none;
  } */
#list_tablePPU thead tr {
  display: block;
}
#list_tablePPU th {
  width: 100px;
}
#list_tablePPU td {
  width: 100px;
  padding: 10px 25px;
}
#list_tablePPU tbody {
  display: block;
  height: 200px;
  overflow: auto;
}
#GOTO_listdisabled {
  color: #000;
  background-color: #b9b9b9;
}

#GOTO_list {
  background-color: #00c8e8;
  color: #fff;
  border: 1px solid #00c8e8;
}

#GOTO_list:hover {
  color: #fff;
  background-color: #18dfff;
  border-color: #18dfff;
}
#PPU_list {
  background-color: #ffffff;
  border: 1px solid #2500a1;
  color: #2500a1;
}
#PPU_list:hover {
  background-color: #2500a1;
  border: 1px solid #2500a1;
  color: #ffffff;
  border-right: 1px solid #ffffff;
}
#PPU_listdisabled {
  color: #000;
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
}
.btnBg {
  background: #2500a1 0% 0% no-repeat padding-box !important;
}
.btnBg:hover {
  background: #4114d9 0% 0% no-repeat padding-box !important;
}
#PPU-btn {
  background-color: #2500a1;
  border: 1px solid #2500a1;
  color: #ffffff;
}
#PPU-btn:hover {
  background-color: #4114d9;
  border: 1px solid #4114d9;
}
.btn-cancel {
  background-color: #ffffff;
  border: 1px solid #2500a1;
  color: #2500a1;
}

.btn-cancel:hover {
  background-color: rgba(37, 0, 161, 0.1);
  border: 1px solid #2500a1;
  color: #2500a1;
}
#firstRow {
  table-layout: fixed !important;
  width: 20%;
  border-bottom: none !important;
  border-left: none !important;
  border-top: none !important;

  text-align: center;
}
#secondRow {
  table-layout: fixed !important;
  width: 20%;
  border-bottom: none !important;
  border-top: none !important;
  text-align: center;
}
#thirdRow {
  table-layout: fixed !important;
  width: 20%;
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;
  text-align: center;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .rowContainer {
    margin: 0px;
    min-width: 1024px;
    text-align: center;
  }
  .container-verf-padding {
    padding: 0px;
  }

  .outerspan {
    display: inline-block;
    width: 60px;
  }
  .sum-header {
    font-size: 16px;
  }
}
#statusIcon {
  display: flex;
  position: relative;
}
#SummaryStatus {
  display: inline-flex;
}
.CpuSummaryClass::before {
  content: url("../../images/CPU_01.png");
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.MemorySummaryClass::before {
  content: url("../../images/Memory_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.NetworkSummaryClass::before {
  content: url("../../images/Network_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.OsSummaryClass::before {
  content: url("../../images/Disk_01.png");
  padding-left: 27px;
  padding-right: 15px;
  float: left;
}
.AspCpuSummaryClass::before {
  content: url("../../images/ASP_CPU_01.png");
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.DbCpuSummaryClass::before {
  content: url("../../images/DB_vCore_01.png");
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.AspMemorySummaryClass::before {
  content: url("../../images/ASP_Memory_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.DbMemorySummaryClass::before {
  content: url("../../images/DB_DataDisk_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.AspNetworkSummaryClass::before {
  content: url("../../images/ASP_Network_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.DbNetworkSummaryClass::before {
  content: url("../../images/DB_LogDisk_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.AspOsSummaryClass::before {
  content: url("../../images/ASP_Disk_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
.DbOsSummaryClass::before {
  content: url("../../images/DB_vCore_Memory_01.png");
  padding-left: 10px;
  padding-right: 15px;
  float: left;
}
#optTbl.navClass {
  margin: auto;
  border-bottom: 0px !important;
}
.navClass {
  margin: auto;
  border-bottom: 6px solid #e8e8e8 !important;
}
#EconimicalRadio {
  display: inline-flex;
  width: 230px;
  border-right: 1px solid rgb(165, 158, 158);
}
#BalancedRadio {
  width: 272px;
  display: inline-flex;

  border-right: 1px solid rgb(165, 158, 158);
}
/* [data-title]:hover:after{
    content: attr(data-title);
    background: white; 
    position: relative;
        left: 74px;
        border: 1px solid gray;
        box-shadow: 0px 0px 10px #888888;
        border-radius: 2px;
        top:20px;
        
} */
#tooltip {
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: center;
}
#tooltip .bottom {
  min-width: 50px;
  max-width: 100px;
  top: 34px;
  left: -10px;
  transform: translate(0, -50%);
  padding: 0;
  color: #393939;
  background-color: white;
  font-weight: normal;
  font-size: 16px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

#tooltip .top {
  min-width: 50px;
  max-width: 100px;
  top: -20px;
  left: -13px;
  transform: translate(0, -50%);
  padding: 0;
  color: #393939;
  background-color: white;
  font-weight: normal;
  font-size: 16px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

#tooltip .right {
  min-width: 50px;
  max-width: 100px;
  top: 10px;
  left: 74px;
  transform: translate(0, -50%);
  padding: 0;
  color: #393939;
  background-color: white;
  font-weight: normal;
  font-size: 16px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

#tooltip .text-content {
  padding: 3px 12px;
}

#tooltip:hover .right,
#tooltip:hover .top,
#tooltip:hover .bottom {
  visibility: visible;
  opacity: 1;
}

#tooltip .right img,
#tooltip .top img,
#tooltip .bottom img {
  width: auto;
  padding: 8px;
  border-radius: 8px 8px 0 0;
}

#tooltip .right i,
#tooltip .top i,
#tooltip .bottom i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}
#tooltip .right i::after,
#tooltip .top i::after,
#tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #444444;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
.new-line {
  white-space: pre-line;
}
.recommendedSelectionRadio {
  height: 20px;
  width: 20px;
}
.radioHeading {
  line-height: 1.8;
  padding-left: 5px;
  font-size: 20px;
}
.dateclass {
  width: 4px;
}
#modalheader_lists {
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  font-size: 30px;
  color: white;
  text-align: center;
  border: none;
  font-weight: 300;
}
.bgColor {
  background-color: #dcfaff;
}

.lightBgColor {
  background-color: #ADD8E6;
}

.blink {
  animation: blinker 0.6s linear infinite;
  font-size: 16px;
  font-weight: bold;
  color: #212529;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.blink-one {
  animation: blinker-one 1s linear infinite;
}
@keyframes blinker-one {
  0% {
    opacity: 0;
  }
}
.blink-two {
  animation: blinker-two 1.4s linear infinite;
}
@keyframes blinker-two {
  100% {
    opacity: 0;
  }
}

.chk_container {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.chk_container input {
  opacity: 0;
  z-index: 1;
  margin: 0px !important;
  cursor: pointer;
  left: 0px;
}
.custom-checkbox {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2px;
  border: 1px solid #757575;
  transition: 0.4s;
  background-color: #ffffff !important;
}
input[type="checkbox"]:disabled + .custom-checkbox {
  background-color: #e2e2e2 !important;
}
input[type="checkbox"]:checked:disabled + .custom-checkbox {
  background-color: #e2e2e2 !important;
  border: 1px solid #757575 !important;
}
input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #2500a1 !important;
  border: 1px solid #2500a1 !important;
}
input[type="checkbox"]:checked + .custom-checkbox:before {
  position: absolute !important;
  content: "";
  justify-content: center;
  align-items: center;
  display: flex;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.footnotePopup {
  padding: 10px;
  border: 1px solid lightgray;
}

.tbodyColor {
  background-color: white;
  font-size: 14px;
}

.selection-input-4 {
  margin: -8px !important;
}

.react-bootstrap-table {
  flex-grow: 1;
  overflow: auto;
  /*    max-height: 37.5vh;*/
}

.valiationGraphParentDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.table-responsive {
  display: table !important;
}

#wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 120px;
  width: 516px;
}
.block {
  width: 90px;
  flex: 0 0 50px;
  margin: 5px;
  background-color: red;
  margin: auto;
  text-align: center;
}
.bigger {
  flex-basis: 110px;
}

.innerTdDivLeftBorder {
  border-left: 1px solid #8e8e8e;
  padding: 2px 0px 2px 2px !important;
}

.no-box-sizing {
  box-sizing: border-box !important;
  max-width: 18px;
  max-height: 18px;
}
.no-box-sizing-overview-icon {
  box-sizing: border-box !important;
  width: 12px;
  height: 12px;
  padding: 1px;
}

no-box-sizing-na {
  box-sizing: border-box !important;
}
.spannedDiv {
  padding: 0px !important;
  align-items: center;
  display: flex;
}
.stackedDiv {
  display: flex;
  height: 33%;
  align-items: center;
}

.stackedDivPerf {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.table-bordered {
  border: 0px !important;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #8e8e8e;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom: 1px solid #8e8e8e;
}

.smallFontTable {
  font-size: 14px;
}

.list_table_height {
  height: 150px;
}

.btn-group {
  height: 36px;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -3px;
}
.fa-info {
  font-size: 24px;
  color: #ffffff;
}
.fa-info:hover {
  font-size: 24px;
}
.vmFilterDiv {
  border-radius: 5px 5px 0px 0px;
  border-color: #d8d8d8;
  margin-bottom: 3px;
}
.vmListDiv {
  border-radius: 0px 0px 5px 5px;
  border-color: #d8d8d8;
  padding: 0px 15px 45px 15px;
}

.list_table_height thead {
  position: sticky;
  top: 115px;
  z-index: 100;
  background-color: white;
}
.list_table_height thead th {
  position: relative;
  border-spacing: 0px !important;
  /* box-shadow: inset 0px 1px 0 #8E8E8E, inset -1px -1px 0 #8E8E8E; */
}
/*.list_table_height thead th::before{
    border-spacing: 0px !important;
    content:'';
    position: absolute;
    height: 41px;
    right:0;
    top:0;
    border:1px solid #8E8E8E;
}
.list_table_height thead th::after{
    border-spacing: 0px !important;
    content:'';
    position: absolute;
    height: 41px;
    left:0;
    top:0;
    border:1px solid #8E8E8E;
}*/
.list_table_inner thead {
  position: sticky;
  top: 0;
  z-index: 0;
}
.list_table_inner thead th {
  border-spacing: 0px !important;
  box-shadow: none;
  background-clip: padding-box;
  border-top: 1px solid #dee2e6 !important;
}
.list_table_inner tbody tr {
  border-top: 1px solid #dee2e6 !important;
}
.thExpandSelector:hover {
  cursor: pointer;
  background-color: #e4fbff;
}

.labelColor {
  color: #393939;
  font-weight: 600;
}

#cchart {
  border-radius: 10px;
}
#pchart {
  border-radius: 10px;
}
.chartDetailStrip {
  word-wrap: anywhere;
  border-right: 2px dashed #cecccc;
  font-size: 18px;
  font-weight: 700;
  min-height: 60px;
  display: grid;
  align-items: center;
}
.chartDetailBottomStrip {
  word-wrap: anywhere;
  border-right: 2px dashed #cecccc;
  min-height: 14px;
  display: block;
}
.chartDetailTopStrip {
  word-wrap: anywhere;
  border-right: 2px dashed #cecccc;
  min-height: 14px;
  display: block;
}
.chartTotalStrip {
  border-top: 2px solid #cecccc;
  border-bottom: 2px solid #cecccc;
}
.chartTotalStripBottomMargin {
  margin-bottom: 20px !important;
}
.chartDetailBorderDashed {
  border-bottom: 2px solid #cecccc;
}
.fa-info-circle {
  font-size: 20px;
  color: #0066ff;
}
.fa-question-circle {
  font-size: 20px;
  color: #ffa700;
}

.fa-exclamation-circle {
  font-size: 20px;
  color: #ffa700;
}

.manageDataSourceLink {
  background-image: url("../../images/DataSource_Icon.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  border-left: 1px white solid;
  border-right: 1px white solid;
}

.dashText {
  font-size: 18px;
  color: #393939;
  text-align: center;
  font-weight: 500;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  color: white;
  position: absolute;
  top: 30px;
  left: 23px;
}
.head-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
  z-index: 1040;
}
.navbarHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 7px;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: auto;
  /* padding: 0 0.5rem 0; */
  padding-right: 5px;
}
.logo-container img {
  height: 42px;
  width: auto;
}
.menu-cont {
  display: flex;
  width: 100%;
}
.menu-tab {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  color: rgba(97, 0, 88, 0.6);
  border-right: 1px solid #fff;
}

.menu-tab:hover {
  background-color: #ffffff;
  color: rgb(97, 0, 88);
  text-decoration: none;
}

.menu-selected {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 22px;
  text-decoration: none;
  color: #610058;
  border-right: 1px solid #fff;
  text-decoration: none !important;
}
#titleCenter a:hover {
  color: #2500a1 !important;
}
.manage-option-btn {
  display: flex;
}
.dropdown-menus {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0.2rem 0.2rem 0.5rem 0.2rem;
}
.dropdown-menu2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600;
  color: #610058;
}

.option-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 67px;
  background-color: #ffffff;
  position: relative;
}

.icon-border {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  background-color: #ffffff;
  border: 1px solid #2500a1;
  border-radius: 4px;
}

.divcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: relative;
  background-color: #7eedff;
}
.option-dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  padding: 5px;
  width: 320px;
  height: auto;
  background-color: #fff;
  position: absolute;
  top: 60px;
  right: 10px;
  border-radius: 10px;
  z-index: 5000;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.dropdown-content ul li {
  list-style: none;
}
.dropdown-content li {
  padding: 0.2rem 0.3rem 0.2rem 0.3rem;
  margin: 0;
}

.dropdown-content ul li a {
  text-decoration: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: block;
}

.dropdown-menu1 a,
.dropdown-menu1 a {
  font-size: 1rem;
  color: rgba(0, 87, 101, 1);
}

.dropdown-list {
  border: 1px solid #2500a1;
  color: #2500a1;
  padding: 2px 7px;
  border-radius: 4px;
  font-weight: 500;
}
.dropdown-list-active {
  background-color: #2500a1;
  color: white;
  padding: 2px 7px;
  border-radius: 4px;
  font-weight: 500;
}
.dropdown-list:hover {
  background-color: #2500a1;
  color: white;
  padding: 2px 7px;
  border-radius: 4px;
  font-weight: 500;
}

.option-btn {
  height: 38px;
  width: 38px;
  background-color: #005cb8;
  border-radius: 38px;
  justify-content: center;
}
.manage-data-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: #23c9e4;
  border-right: 1px solid #fff;
}

.manage-data-cont:hover {
  background-color: #04bbd9;
}

.manage-data-cont-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: #7eedff;
  border-right: 1px solid #fff;
}
.manage-data-cont-selected:hover {
  background-color: #7eedff;
}

.manage-data-btn {
  height: 50px;
  width: 50px;
  background-image: url("../../images/DataSource_Icon.svg");
  background-size: 96%;
  background-position: center;
  background-repeat: no-repeat;
}
.option-btn::before {
  display: block;
  content: url("../../images/DownArrowHead_01.svg");
  position: absolute;
  top: 16px;
  left: 21px;
}
.option-btn:hover::before {
  display: block;
  content: url("../../images/UpArrowHead_01.svg");
  position: absolute;
  top: 16px;
  left: 21px;
  transition: all 200ms 0s ease-in-out;
  transform: rotate(360deg);
}
.srSwitch {
  width: 102px;
  height: 42px;
  margin-right: 10px;
}
.e-switch-wrapper .e-switch-on {
  text-indent: -40px;
  background-color: #2500a1;
}
.e-switch-wrapper .e-switch-off {
  text-indent: 38px;
  background-color: #c2c2c2;
  color: #5d5d5d;
  opacity: 1;
}

.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #2500a1;
}
.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-switch-wrapper:hover .e-switch-handle.e-switch-active {
  background-color: white;
}
.e-switch-wrapper .e-switch-inner {
  border-radius: 10px;
}
.e-switch-wrapper .e-switch-handle {
  border-radius: 8px;
  width: 38px;
  height: 38px;
  left: 2px;
}
.e-switch-wrapper .e-switch-handle.e-switch-active {
  left: 78%;
}
.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on {
  opacity: 1;
}

/*svg icon classes*/
.cpu-svg {
  width: 68px;
  height: 68px;
  position: relative;
}
.memory-svg {
  width: 80px;
  height: 68px;
  position: relative;
}
.network-svg {
  width: 76px;
  height: 68px;
  position: relative;
}
.os-disk-svg {
  width: 52px;
  height: 68px;
  position: relative;
}
.mid-disk-svg {
  width: 26px;
  height: 68px;
  position: relative;
}
.end-disk-svg {
  width: 26px;
  height: 68px;
  position: relative;
}
.poor,
.abnormal {
  fill: #c9393d;
}
.fair,
.borderline {
  fill: #deae25;
}
.good,
.normal {
  fill: #448744;
}
.na {
  fill: #7d7d7d;
}

.info-graphics-container {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 0.3rem;
  align-items: center;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}
.shadow {
  opacity: 0.25;
}
.marker-icon-container {
  display: flex;
  justify-content: center;
  /*    margin: 0 0 1rem 0;*/
}
.marker-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 68px;
}
.cost-marker {
  width: 40px;
  font-size: 1rem;
  color: #747474;
  margin-right: -0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dotted-line {
  border-bottom: 2px dotted #c7c7c7;
}
.perf-marker {
  width: 40px;
  font-size: 1rem;
  color: #747474;
  margin-right: -0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cpuIconPart1 {
  fill: #7d7d7d;
}
.arrow {
  fill: #7d7d7d;
}
.cpuIconPart2 {
  fill: #d6d6d6;
}
.bondingBox {
  fill: none;
}
.info-graphic-empty {
  width: 68px;
  height: 68px;
}
.info-graphic02-empty {
  width: 72px;
  height: 68px;
}
.arrow-right {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ArrowRight_01.svg");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.3rem 0 0.4rem;
}
.memoryIcon {
  fill: #d6d6d6;
  stroke: #7d7d7d;
  stroke-miterlimit: 10;
}
.networkIcon {
  fill: #d6d6d6;
  stroke: #7d7d7d;
}
.osDiskIcon {
  fill: #d6d6d6;
  stroke: #7d7d7d;
  stroke-miterlimit: 10;
}
.diskCenter {
  fill: #7d7d7d;
}
.midDiskPart1,
.midDiskPart2,
.midDiskPart3 {
  fill: #7d7d7d;
}
.midDiskPart4 {
  fill: #d6d6d6;
}
.endDiskPart1,
.endDiskPart2,
.endDiskPart3 {
  fill: #7d7d7d;
}
.endDiskPart4 {
  fill: #d6d6d6;
}
.os-disk-arrow-svg {
  width: 39px;
  height: 68px;
}
.mid-disk-arrow-svg {
  width: 19px;
  height: 68px;
}
.end-disk-arrow-svg {
  width: 19px;
  height: 68px;
}
.center {
  fill: #c1c1c1;
}
.separator {
  width: 1px;
  height: auto;
  background-color: #bfbfbf;
}
.small-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 68px;
}
.cost-small-icon {
  width: 20px;
  height: 14px;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.3rem 0 0.4rem;
}
.pref-small-icon {
  width: 20px;
  height: 14px;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.3rem 0 0.4rem;
}
.y-tick {
  background-image: url("../../images/GoodTickIcon_01.svg");
}
.m-tick {
  background-image: url("../../images/FairTickIcon_01.svg");
}
.n-tick {
  background-image: url("../../images/PoorCrossIcon_01.svg");
}
.skip-tick {
  background-image: url("../../images/Skipped_01.svg");
}
.na-tick {
  background-image: url("../../images/NotApplicable_01.svg");
}
.star-tick {
  background-image: url("../../images/NotDeterminable_01.svg");
}
.pending {
  background-image: url("../../images/Pendding_01.svg");
}
.custom-sized {
  background-image: url("../../images/CustomSized_01.svg");
}
.optimized {
  background-image: url("../../images/Optimized_01.svg");
}
.state-container-resize {
  display: flex;
  margin-bottom: 0.2rem;
  justify-content: flex-end;
}
.icon-wrapper {
  display: flex;
  flex-direction: column;
}
.state-icon-resize {
  width: 70px;
  justify-content: center;
  display: flex;
  margin-right: 0.5rem;
}
.state-icon {
  width: 20px;
  height: 20px;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  /*margin: 0 0.3rem 0 0.4rem;*/
}
.disk-state-icon-resize {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 8px;
}
.os-disk-state-icon-cont {
  width: 39px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.data-disk-state-icon-cont {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.disk-state-icon {
  width: 20px;
  height: 20px;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  /*margin: 0 0.3rem 0 0.4rem;*/
}
.state-container {
  display: flex;
  margin-bottom: 0.2rem;
}
.space-filler {
  flex-grow: 1;
}
.state-name1 {
  flex-grow: 2;
  justify-content: center;
  display: flex;
  font-size: 0.9rem;
  color: #797979;
}
.state-name2 {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  font-size: 0.9rem;
  color: #797979;
}
.space-filler1 {
  width: 32%;
}
.space-filler2 {
  width: 42%;
}
.space-filler3 {
  width: 5%;
}
.space-filler4 {
  width: 80%;
}
.mem-state-container,
.network-state-container,
.disk-state-container {
  display: flex;
  margin-bottom: 0.2rem;
}

.mem-state-name1,
.net-state-name1,
.disk-state-name1 {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  font-size: 0.9rem;
  width: 100%;
  padding-right: 0.3rem;
  color: #797979;
}
.mem-state-name2,
.net-state-name2,
.disk-state-name2 {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-size: 0.9rem;
  width: 100%;
  padding-left: 0rem;
  color: #797979;
}
.disk-space-filler {
  width: 35%;
}
.disk-small-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 68px;
}
.icon-number-cont {
  display: flex;
}
.disk-icon-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.disk-cost-small-icon,
.disk-pref-small-icon {
  width: 20px;
  height: 14px;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
}

.disk-number {
  font-size: 0.5rem;
}
.right-arrow-cont {
  display: flex;
  justify-content: center;
}
.disk-arrow-right {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ArrowRight_01.svg");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.3rem 0 0.4rem;
}
.data-disk-container,
.log-disk-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}
.grayed-out {
  fill: #bdbdbd;
}
.vCore-Mem-svg {
  width: 68px;
  height: 68px;
  position: relative;
}
.data-disk-svg {
  width: 53px;
  height: 68px;
  position: relative;
}
.log-disk-svg {
  width: 49px;
  height: 68px;
  position: relative;
}
.cpu-fill {
  fill: #d6d6d6;
}
.cpu-border {
  fill: #7d7d7d;
}
.mem-border {
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
  fill: #d6d6d6;
}
.vCore-mem-icon,
.data-disk {
  stroke: #7d7d7d;
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
  fill: #d6d6d6;
}
.vCoreMem-info-graphic-empty {
  width: 68px;
  height: 68px;
}
.log-disk-arrow-svg {
  width: 68px;
  height: 68px;
}
.log-disk {
  stroke: #7d7d7d;
  stroke-miterlimit: 10;
  stroke-width: 0.5294px;
  fill: #fff;
}
.log-file {
  stroke: #7d7d7d;
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
  fill: #d6d6d6;
}
.pref-normal {
  background-image: url("../../images/Green_011.png");
}
.pref-borderline {
  background-image: url("../../images/Yellow_013.png");
}
.pref-abnormal {
  background-image: url("../../images/Red_012.png");
}
.head-titles,
.head-titles-resize {
  display: flex;
  width: 100%;
  padding: 0 0 0.5rem 0;
}
.title-col-1,
.title-col-2,
.title-col-3,
.title-col-4 {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}
.title-col-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 0px;
}
.title-col-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-wrapper {
  display: flex;
  width: 95%;
  margin: 1rem 0 0 0;
}
.validation-table-container,
.table-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.disk-validation-svg {
  width: 39px;
  height: 68px;
  margin: auto;
}
.info-cell {
  display: flex;
  width: 100%;
  border-top: 1px solid #c8c8c8;
}
.info-cell-1,
.info-cell-2,
.info-cell-3,
.info-cell-4 {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}
.info-cell-5 {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.info-cell-6 {
  display: flex;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
}
.vali-state-icon-cont {
  width: 18px;
  height: 18px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.3rem 0 0.4rem;
}
.resize-state-icon-cont {
  width: 38px;
  height: 38px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.3rem 0 0.4rem;
}
.text-style {
  justify-content: flex-start;
  font-size: 1.1rem;
}
.cost-tooltip-box {
  display: none;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 24px;
  position: absolute;
  top: -29px;
  left: 2px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dadada;
  box-shadow: 0px 0px 8px #d6d6d6;
  font-weight: 700;
  z-index: 200;
}
.pref-tooltip-box {
  display: none;
  width: 62px;
  height: 24px;
  position: absolute;
  bottom: -29px;
  left: 2px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dadada;
  box-shadow: 0px 0px 8px #d6d6d6;
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 200;
}

.svgColorIconContainer {
  width: 130px;
  display: flex;
  justify-content: center;
}
.svgGreyIconContainer {
  width: 105px;
  display: flex;
  justify-content: center;
}
.svgValidationIcoContainer {
  display: flex;
  justify-content: center;
}
.collapseTransitionMenu {
  transition: all 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  min-height: 0px;
}
.boxShadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #41a1be59;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 24px !important;
}

#titleCenter {
  font-weight: 600;
}

.HeadingSignIn {
  margin-top: 66px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #610058;
}
.HeadingTitleSignIn {
  width: 100%;
  text-align: center;
  color: #393939;
  font: normal normal normal 34px/10px Poppins;
  margin: 45px 0;
}

.tableBgHeader {
  background: transparent linear-gradient(270deg, #20008e 0%, #8e0081 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  color: white;
}
.tableTextCenter tr td {
  text-align: center !important;
  /* color:red !important */
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: unset;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.reportChartLabel {
  font-size: 20px;
  font-weight: 500;
  color: #393939;
}
button:focus {
  outline: unset;
}
.tdColor {
  color: #393939;
  font-weight: 500;
}
.labelColor {
  color: #393939;
  font-weight: 600;
}
#listBtn {
  background: #2500a1 0% 0% no-repeat padding-box !important;
  border: 1px solid #2500a1 !important;
  border-radius: 6px 0px 0px 6px;
  opacity: 1;
}
#listBtn:hover {
  background-color: #4114d9 !important;
  border: 1px solid #4114d9 !important;
  color: #ffffff;
  border-right: 1px solid #ffffff;
}
#listDisabledBtn {
  background: #d8d8d8 0% 0% no-repeat padding-box !important;
  border: 1px solid #c4c4c4 !important;
  opacity: 1;
  color: #808080;
}
.listInfoBtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #2500a1 !important;
  border-radius: 0px 6px 6px 0px;
  opacity: 1;
}
.btn-group .fa-info::before {
  color: #2500a1 !important;
  font-size: 20px;
  line-height: 6px;
  text-align: center;
  vertical-align: middle;
}
.listInfoBtn:hover {
  border: 1px solid #4114d9 !important;
}
.btn-group .fa-info:hover::before {
  color: #4114d9 !important;
}

@media only screen and (max-width: 600px) {
  .signInImg {
    display: none;
  }
}
.signInImg {
  background-image: url("../../images/SerraLabsLogIn_Background.png");
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
.signInNoSerra {
  background-image: url("../../images/NoSerra_background.png");
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
.signInForm {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-left: 60px;
  padding-right: 60px;
}
.textPosition {
  margin: 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font: normal normal 600 66px/112px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.textPositionHP {
  text-align: center;
  font: normal normal 600 28px/60px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 110px;
  white-space: nowrap;
}
#btnCards {
  background-color: #2500a1;
  border: 1px solid #2500a1;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
}
#btnCards:hover {
  background-color: #4114d9;
  border: 1px solid #4114d9;
}
.signInLogo {
  margin-top: 30px;
  box-sizing: border-box;
  background-repeat: no-repeat;
}
.signInLogo img {
  width: 120px;
}
#btnRefresh {
  background-color: #2500a1;
  border: 1px solid #2500a1;
  border-radius: 3px;
  color: #fff;
  height: unset !important;
}
#btnRefresh:hover {
  background-color: #4114d9;
  border: 1px solid #4114d9;
}
.powerByDiv {
  font-size: 12px;
  text-align: center;
  background-color: #4f0088;
  color: white;
  width: 140px;
  margin: auto;
}
.vmsizeTbl {
  word-break: break-all;
}
.benefitsTblCol {
  font-weight: 400;
  color: #007bff;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
}

.benefitsTblCol:hover {
  color: #0056b3;
  text-decoration: underline;
}
.arrowImg {
  display: unset !important;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.rmpaddingChart {
  padding-left: 0px;
  padding-right: 0px;
}
.orgBtn {
  background: #2500a1 0% 0% no-repeat padding-box !important;
  border: 1px solid #2500a1 !important;
  opacity: 1;
  color: #ffffff;
  border-radius: 6px;
  padding: 3px 0px;
  cursor: pointer;
}
.orgBtn:hover {
  background-color: #4114d9 !important;
  border: 1px solid #4114d9 !important;
  color: #ffffff;
}
.orgBtnDisable {
  background: #d8d8d8 0% 0% no-repeat padding-box !important;
  border: 1px solid #c4c4c4 !important;
  opacity: 1;
  color: #808080;
  border-radius: 6px 0px 0px 6px;
  padding: 3px 0px;
}

.orgInfoBtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #2500a1 !important;
  border-radius: 0px 6px 6px 0px;
  opacity: 1;
  width: 30px;
  padding: 1px;
}
.orgInfoBtn .fa-info::before {
  color: #2500a1 !important;
  font-size: 20px;
  line-height: 6px;
  text-align: center;
  padding: 5px 9px;
}
.orgInfoBtn:hover {
  border: 1px solid #4114d9 !important;
}
.orgInfoBtn .fa-info:hover::before {
  color: #4114d9 !important;
}
.custom-datepicker {
  width: 200px;
  height: 36px;
}

@media (min-width: 1600px) {
  .orgHistoryDetails.modal-xl {
    max-width: 1600px !important;
  }
}

.dateContainer {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

@media (max-width: 1050px) {
  .dateContainer {
    display: inline-grid;
    gap: 1px;
  }
}

.tooltip {
  text-align: -webkit-center;
  font: 14px Poppins, sans-serif;
}
.tooltip .tooltip-inner {
  max-width: 45%;
  opacity: 1;
}
.tooltip.show {
  opacity: 1 !important;
}

.dashboardPieChartEmpty {
  margin-bottom: 100px;
}
.emptyDivChartTitle {
  padding-bottom: 50px;
  padding-top: 50px;
  font-style: normal;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: rgb(33, 37, 41);
}
.requiredColor {
  color: red;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("../../images/icons-cross.png") !important;
}

.vertical-hr {
  width: 2px; /* Line thickness */
  height: 70%; /* Line height */
  background-color: #c9c9c9; /* Line color */
  border: none; /* Remove default border */
}
