
body {
  padding-right: 0px !important;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'Lato', sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}
/* code {
  font-family: 'Lato', sans-serif;
} */
